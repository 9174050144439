<template>
  <div class="page-gray">
    <van-cell-group>
      <van-field v-model="form.receiverName" label="收  件  人:" placeholder="请输入用户名"/>
      <van-field :value="cityText" is-link label="所在地区:" readonly @click="show = true" placeholder="请选择"/>
      <van-field
          v-model="form.address"
          rows="1"
          autosize
          label="详细地址:"
          type="textarea"
          placeholder="请填写详细地址(如街道、小区、门牌号)"
      />
      <van-field v-model="form.phone" label="联系电话:" placeholder="请输入有效号码" @change="checkPhone" :border="false"
                 :style="{'padding-bottom': validation ? 0 : ''}"/>
      <van-field v-if="validation">
        <span slot="label">&nbsp;</span>
        <div slot="input" class="code">
          <input v-model="form.code" type="text" class="van-field__control" placeholder="请输入验证码"/>
          <van-button type="primary" @click="sendMessage" v-if="seconds === MAX_SECONDS">获取验证码</van-button>
          <van-button type="info" v-else>{{ seconds }}秒后重新发送</van-button>
        </div>
      </van-field>
    </van-cell-group>

    <van-cell-group :style="{marginTop: px2rem(18)}" v-if="type === 'add'">
      <van-cell center title="设为默认">
        <template #right-icon>
          <van-switch v-model="form.isDefault" size="24"/>
        </template>
      </van-cell>
    </van-cell-group>

    <div class="tips">温馨提示：收货地址建议填写家庭地址，不要邮寄到学校。</div>
    <div class="saveBtn">
      <van-button type="primary" block @click="submit">保存</van-button>
    </div>

    <van-popup v-model="show" round position="bottom">
      <van-cascader
          title="请选择所在地区"
          :options="menuList"
          :field-names="{
                    text: 'areaName',
                    value: 'areaId',
                    children: 'children'
                }"
          @close="show = false"
          @finish="onAreaFinish"
      />
    </van-popup>
    <Vcode :show="isShow" @success="onSuccess"/>

  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import {treeDataTranslate} from '@/util/util'
// import {regionData} from 'element-china-area-data'
import Vue from "vue";



const MAX_SECONDS = 300;
export default {
  name: "AddressHandle",
  components: {
    Vcode
  },
  data() {
    return {
      isShow: false,
      validation: false,
      menuList: [],
      show: false,
      seconds: MAX_SECONDS,
      MAX_SECONDS,
      cityText: '',
      type:'',
      form: {
        receiverName: '',
        phone: '',
        code: '',
        address: '',
        isDefault: 0,
        provinceId: '',
        cityId: '',
        districtId: '',
        province: '',
        city: '',
        district: ''
      }
    }
  },
  created() {
    this.queryArea();
  },
  mounted() {
    this.type = this.$route.meta.type;
    if (this.type === 'add') {
      // 获取当前选择的学校
    } else {
      this.receiverId = this.$route.params.id
      // TODO send Server
      this.$http.get(this.baseMallUrl + `/h5_mall/queryReceiverAddress/${this.receiverId}`).then(res => {
        res.data.isDefault = Boolean(res.data.isDefault)
        this.form = res.data;
        this.cityText = res.data.province+'/'+res.data.city+'/'+res.data.district
      })

    }
  },
  methods: {
    queryArea() {
      this.$http.get(`/product/area/getList`).then(res => {
        this.menuList = treeDataTranslate(res.data, 'areaId', 'parentId');
      });
    },
    checkPhone() {
      let reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!reg.test(this.form.phone)) {
        return;
      }
      this.isShow = true
    },
    onSuccess() {
      this.validation = true
      this.isShow = false
    },
    sendMessage() {
      if (!this.form.phone) {
        this.$toast('请输入手机号码');
        return;
      }

      let reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!reg.test(this.form.phone)) {
        this.$toast('请输入正确的手机号码');
        return;
      }
      this.$toast.loading({duration: 0, forbidClick: true, text: '正在发送'});
      this.$http.get(`/mall/h5_mall/sendSMSToUsers/${this.form.phone}/${Vue.prototype.deptId}`).then(res => {
        res
        this.$toast.success('发送成功');
        this.computedTime()
      });
    },
    computedTime() {
      this.seconds--;
      if (this.seconds > 0) {
        setTimeout(() => {
          this.computedTime()
        }, 1000)
      } else {
        this.seconds = MAX_SECONDS
      }
    },
    onAreaFinish({selectedOptions}) {
      this.show = false;
      this.form.provinceId = selectedOptions[0].areaId;
      this.form.cityId = selectedOptions[1].areaId;
      this.form.districtId = selectedOptions[2].areaId;
      this.form.province = selectedOptions[0].areaName;
      this.form.city = selectedOptions[1].areaName;
      this.form.district = selectedOptions[2].areaName;
      this.cityText = selectedOptions.map((option) => option.areaName).join('/');
    },

    submit() {
      if (!this.form.receiverName) {
        this.$toast('请输入收件人姓名');
        return;
      }

      // let nameReg = /[`~!@#$^\-&*()=|{}':;',\\[\].<>/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
      // if (nameReg.test(this.form.receiverName)) {
      //   this.$toast('请输入正确的姓名');
      //   return;
      // }

      let nameRule=/^[a-zA-Z\u4e00-\u9fa5]+( [a-zA-Z\u4e00-\u9fa5]+)*$/
      if(!nameRule.test(this.form.receiverName)){
        this.$toast('收货人信息不符合规范，应由汉字或英文字母组成');
        return;
      }
      if(this.form.receiverName.length>15){
        this.$toast('收货人信息最多为15个字符');
        return;
      }

      if (!this.form.provinceId) {
        this.$toast('请选择地区');
        return;
      }

      if (!this.form.address) {
        this.$toast('请输入详细地址');
        return;
      }

      if (!this.form.address.length>80) {
        this.$toast('详细地址不能超过80个字');
        return;
      }

      if (!this.form.phone) {
        this.$toast('请输入手机号码');
        return;
      }

      let reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!reg.test(this.form.phone)) {
        this.$toast('请输入正确的手机号码');
        return;
      }

      if(this.type !== 'edit'){
        if (!this.form.code) {
          this.$toast('请输入验证码');
          return;
        }
      }

      // TODO
      this.$toast.loading({duration: 0, forbidClick: true});
      this.form.useDept = Vue.prototype.deptId;
      this.form.isDefault = Number(this.form.isDefault);

      if(this.type === 'edit'){
        this.$http.post(this.baseMallUrl + `/h5_mall/updateBuyerUserAddress`, this.form, {emulateJSON: true}).then(() => {
          this.$toast.success('修改成功');
          this.$router.back()
        })
      }else{
        this.$http.post(this.baseMallUrl + `/h5_mall/saveBuyerUserAddress`, this.form, {emulateJSON: true}).then(() => {
          this.$toast.success('新增成功');
          this.$router.back()
        })
      }
    },
  }
}

</script>
<style lang="less" scoped>
.van-cell {
  padding: 14px 17px;
}

.code {
  display: flex;

  input {
    border-radius: 4px 0 0 4px;
    background-color: #EEEEEE;
    height: 30px;
    border: none;
    padding: 0 9px;
    flex: 1;
  }

  .van-button {
    border-radius: 0 4px 4px 0 !important;
    height: 30px;
    font-size: 12px;
    padding: 0 9px;
    white-space: nowrap;
  }
}

.tips {
  line-height: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #FF0000;
  padding: 11px 17px;
}

.saveBtn {
  position: absolute;
  bottom: 30px;
  left: 18px;
  right: 18px;
}
</style>
